const isRefreshReducer = (state = { isRefresh: true }, action) => {
  switch (action.type) {
    case 'SET_REFRESH':
      return {
        ...state,
        isRefresh: action.isRefresh,
      }
    default:
      return state
  }
}

export default isRefreshReducer
