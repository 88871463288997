export const PENDING = 'PENDING'
export const SUCCESS = 'SUCCESS'
export const FAILED = 'FAILED'
export const LOGOUT = 'LOGOUT'
export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const TOGGLE_FORM = 'TOGGLE_FORM'
export const PLAY = 'PLAY'
export const PAUSE = 'PAUSE'
export const PLAYER_REF = 'PLAYER_REF'
export const REMOVE_PLAYER = 'REMOVE_PLAYER'
export const HIDDEN = 'HIDDEN'
export const SET_SAVED_PLAYBACK_TIME = 'SET_SAVED_PLAYBACK_TIME'
