import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import rootReducers from '.'
import { isBrowser } from './async/auth'

function savePlayerStateToLocalStorage(playerState) {
  try {
    const playerStateToSave = {
      isPlaying: playerState.isPlaying,
      isPause: playerState.isPause,
      songUrl: playerState.songUrl,
      songName: playerState.songName,
      songTitle: playerState.songTitle,
      savedPlaybackTime: playerState.savedPlaybackTime,
    }
    const serializedState = JSON.stringify(playerStateToSave)
    isBrowser() && localStorage.setItem('persistedPlayerState', serializedState)
  } catch (err) {
    console.error(err)
  }
}

function loadPlayerStateFromLocalStorage() {
  try {
    const serializedState =
      isBrowser() && localStorage.getItem('persistedPlayerState')
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (err) {
    console.error(err)
    return undefined
  }
}

const playerState = loadPlayerStateFromLocalStorage()

const store = createStore(
  rootReducers,
  {
    player: playerState,
  },
  applyMiddleware(thunk)
)

store.subscribe(() => {
  const { player } = store.getState()
  savePlayerStateToLocalStorage(player)
})

export default store
