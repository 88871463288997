import {
  HIDDEN,
  PAUSE,
  PLAY,
  PLAYER_REF,
  REMOVE_PLAYER,
  SET_SAVED_PLAYBACK_TIME,
} from '../types'

const playerReducer = (state = { isPause: true }, action) => {
  switch (action.type) {
    case PLAYER_REF:
      return {
        ...state,
        playerRef: action.payload.playerRef,
      }
    case PLAY:
      return {
        ...state,
        songUrl: action.payload.songUrl,
        songName: action.payload.songName,
        songTitle: action.payload.songTitle,
        savedPlaybackTime: null,
        isPlaying: true,
        isPause: false,
      }
    case PAUSE:
      return {
        ...state,
        isPause: true,
      }
    case REMOVE_PLAYER:
      return {
        ...state,
        savedPlaybackTime: null,
        songUrl: '',
        songName: '',
        songTitle: '',
      }
    case HIDDEN:
      return {
        ...state,
        isPause: false,
        isPlaying: false,
      }
    case SET_SAVED_PLAYBACK_TIME:
      return {
        ...state,
        savedPlaybackTime: action.payload,
      }
    default:
      break
  }
  return state
}

export default playerReducer
