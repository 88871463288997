import {
  getAccessToken,
  isTokenAlive,
  removeAccessToken,
  removeUser,
} from '../async/auth'
import { FAILED, LOGOUT, PENDING, SUCCESS } from '../types'

const token = getAccessToken()
const initialState = {
  isLoggedIn: isTokenAlive(token),
  error: null,
  isLoading: false,
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case PENDING:
      return {
        ...state,
        isLoading: true,
      }
    case SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
      }
    case FAILED:
      return {
        isLoading: false,
        error: action.payload,
      }
    case LOGOUT:
      removeAccessToken()
      removeUser()
      return {
        isLoggedIn: false,
      }
    default:
      return state
  }
}
