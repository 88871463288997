import { TOGGLE_FORM, TOGGLE_MODAL } from '../types'

const initialState = {
  modalVisible: false,
  currentForm: 'SignInForm',
}

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        modalVisible: !state.modalVisible,
      }
    case TOGGLE_FORM:
      return {
        ...state,
        currentForm:
          state.currentForm === 'SignInForm' ? 'SignUpForm' : 'SignInForm',
      }
    default:
      return state
  }
}
