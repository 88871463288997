const initialState = {
  avatartUrl: null,
  email: '',
  id: '',
  username: '',
  //   isPassword: false,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_USER_DATA':
      if (action.payload && action.payload.username) {
        return {
          ...state,
          id: action.payload.id,
          username: action.payload.username,
          email: action.payload.email,
          avatartUrl: action.payload.avatartUrl,
          isPassword: action.payload.isPassword,
        }
      }
      return state
    case 'UPDATE_USER_DATA':
      return { ...state, ...action.payload }
    case 'REFRESH_USER_DATA':
      if (action.payload) {
        return state
      }
      return state
    default:
      return state
  }
}

export default userReducer
