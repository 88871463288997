import { jwtDecode } from 'jwt-decode'
import { failed, pending, success } from '../actions/authActions'
import { toggleModal } from '../actions/modalActions'

export const isBrowser = () => typeof window !== 'undefined'
const expirationDate = new Date()
expirationDate.setDate(expirationDate.getDate() + 3) // expiration time 3 days

function getCookie(name) {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1)
    }
  }
  return null
}

export const setUser = accessToken => {
  const { username, email } = jwtDecode(accessToken)
  document.cookie = `user=${JSON.stringify({
    username,
    email,
  })}; expires=${expirationDate.toUTCString()}; path=/`
}

export const setAccessToken = accessToken => {
  document.cookie = `accessToken=${accessToken}; expires=${expirationDate.toUTCString()}; path=/`
}

export const getAccessToken = () => {
  const accessToken = isBrowser() && getCookie('accessToken')
  return accessToken || null
}

export const getUser = () =>
  isBrowser() && getCookie('user') ? JSON.parse(getCookie('user')) : {}

export const removeAccessToken = () => {
  document.cookie =
    'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}

export const removeUser = () => {
  document.cookie = 'user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}

export const isTokenAlive = token => {
  if (!token) return false
  const { exp } = jwtDecode(token)
  const currentTime = Math.floor(Date.now() / 1000)
  return currentTime < exp
}

export default function fetchData(data, authFunc) {
  return async dispatch => {
    dispatch(pending())
    try {
      const { accessToken } = await authFunc(data)
      setUser(accessToken)
      setAccessToken(accessToken)
      dispatch(success())
      dispatch(toggleModal())
    } catch (error) {
      dispatch(failed(error.message))
    }
  }
}
