import { combineReducers } from 'redux'
import { reducer as jPlayers } from 'react-jplayer'
import modalReducer from './reducers/modalReducer'
import authReducer from './reducers/authReducer'
import playerReducer from './reducers/player'
import isRefreshReducer from './reducers/refresh'
import userReducer from './reducers/user'

const rootReducers = combineReducers({
  modal: modalReducer,
  auth: authReducer,
  player: playerReducer,
  refresh: isRefreshReducer,
  user: userReducer,
  jPlayers,
})

export default rootReducers
